import React from 'react'
import { Button, Layout, Dropdown, Icon, Menu, Divider, message } from 'antd'

import logo from '../assets/images/logo2.png'
import langEN from '../assets/images/en.png'
import langIT from '../assets/images/it.png'
import langES from '../assets/images/es.png'
import langFR from '../assets/images/fr.png'
import langPT from '../assets/images/pt.png'

import socketDataEN from './socketDataEN.json'
import socketDataES from './socketDataES.json'
import socketDataIT from './socketDataIT.json'
import socketDataFR from './socketDataFR.json'
import socketDataPT from './socketDataPT.json'

const Header = props => {
  const handleClick = language => () => {
    props.setLanguage(language)

    switch (language) {
      case 'en':
        props.setLanguageMenu(socketDataEN.menu)
        break
      case 'es':
        props.setLanguageMenu(socketDataES.menu)
        break
      case 'it':
        props.setLanguageMenu(socketDataIT.menu)
        break
      case 'fr':
        props.setLanguageMenu(socketDataFR.menu)
        break
      case 'pt':
        props.setLanguageMenu(socketDataPT.menu)
        break
    }
  }

  const handleOptionClick = option => () => {
    props.setOption(option)
  }

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleOptionClick('socket')}>
        {props.menu_language.socket_sublimations}
      </Menu.Item>
      <Menu.Item key="2" onClick={handleOptionClick('library')}>
        {props.menu_language.damage_meter}
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout.Header className="header">
      <div className="maxwidth">
        <img className="logo" src={logo} alt="logo" />

        <Button className="lang" onClick={handleClick('en')}>
          <img className="size" src={langEN} alt="english" />
        </Button>
        <Button className="lang" onClick={handleClick('es')}>
          <img className="size" src={langES} alt="spanish" />
        </Button>
        <Button className="lang" onClick={handleClick('it')}>
          <img className="size" src={langIT} alt="italian" />
        </Button>
        <Button className="lang" onClick={handleClick('fr')}>
          <img className="size" src={langFR} alt="french" />
        </Button>
        <Button className="lang" onClick={handleClick('pt')}>
          <img className="size" src={langPT} alt="portuguese" />
        </Button>
        <Dropdown className="option" overlay={menu} trigger={['click']}>
          <Button className="option">
            {props.menu_language.tools} <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
    </Layout.Header>
  )
}

export default Header
