import React from 'react'
import { Layout, Divider } from 'antd'

import discord from '../assets/images/discord.png'
import logo from '../assets/images/logo-method-main.webp'

import socketDataEN from './socketDataEN.json'
import socketDataES from './socketDataES.json'
import socketDataIT from './socketDataIT.json'
import socketDataFR from './socketDataFR.json'
import socketDataPT from './socketDataPT.json'

/////////////// Donation links
// <br />
// Buy me a coffee? :D
// <form
//   action="https://www.paypal.com/cgi-bin/webscr"
//   method="post"
//   target="_top"
// >
//   <input type="hidden" name="cmd" value="_donations" />
//   <input type="hidden" name="business" value="G5E4TKGRM9VVS" />
//   <input type="hidden" name="currency_code" value="USD" />
//   <input
//     type="image"
//     src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
//     border="0"
//     name="submit"
//     title="PayPal - The safer, easier way to pay online!"
//     alt="Donate with PayPal button"
//   />
//   <img alt="" border="0" src="" width="1" height="1" />
// </form>
// <br />

const Footer = props => {
  return (
    <Layout.Footer className="footer">
      <Divider />
      <br />
      <strong>{props.menu_language.q}</strong>
      <br />
      <img class="discord-icon" src={discord} /> jpark#0450 (Zephyrs (Nox))
      <br />
      <br />
      <strong>{props.menu_language.credits}</strong>
      <br /> {props.menu_language.it}
      <a href="https://oropoproject.wordpress.com/"> Oropo Project</a>
      <br /> {props.menu_language.fr}
      <a href="https://www.zenithwakfu.com/"> Zenith Wakfu</a>
      <br /> {props.menu_language.es} <img class="discord-icon" src={discord} />
      <a href="https://discord.gg/atuQQX3"> Wakfu Comunidad Hispáñica</a>
      <br />
      <img class="discord-icon" src={discord} /> Mono#6352
      <br /> {props.menu_language.pt}
      <img class="discord-icon" src={discord} /> Sams: SamuelOliveira#2787
      <br />
      <br />
      <img class="footer-logo" src={logo} />
      <br />
      {props.menu_language.disclaimer1}
      <br />
      {props.menu_language.disclaimer2}
      <br />
    </Layout.Footer>
  )
}

export default Footer
