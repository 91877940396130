import React from 'react'
import { Layout, Divider } from 'antd'

import GemSelect from './GemSelect'
import OutputTable from './OutputTable'
import OutputTableM from './OutputTableM'
import FadeIn from 'react-fade-in'

import background from '../assets/images/fond.jpg'

const { Content } = Layout

class MainContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      matched: [],
      permutations: [],
      menu_language: 'socketDataEN.menu',
    }
  }

  updatePermutations = p => {
    this.setState({ permutations: p })
  }

  updateMatched = p => {
    this.setState({ matched: p })
  }

  render() {
    return (
      <Content className="content">
        <Divider />
        <GemSelect
          permutations={this.state.permutations}
          matched={this.state.matched}
          updatePermutations={this.updatePermutations}
          updateMatched={this.updateMatched}
          menu_language={this.props.menu_language}
        />
        <Layout className="largeviewport">
          <OutputTable
            language={this.props.language}
            permutations={this.state.permutations}
            matched={this.state.matched}
            updatePermutations={this.updatePermutations}
            updateMatched={this.updateMatched}
            menu_language={this.props.menu_language}
          />
        </Layout>
        <Layout className="smallviewport">
          <OutputTableM
            language={this.props.language}
            permutations={this.state.permutations}
            matched={this.state.matched}
            updatePermutations={this.updatePermutations}
            updateMatched={this.updateMatched}
            menu_language={this.props.menu_language}
          />
        </Layout>
      </Content>
    )
  }
}

export default MainContent
