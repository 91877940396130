import React, { useState, useEffect } from 'react'
import { Layout } from 'antd'
import ReactGA from 'react-ga'
import FadeIn from 'react-fade-in'

import MainContent from './components/MainContent'
import MainContentMeter from './components/MainContentMeter'
import TxtInput from './components/TxtInput'
import Footer from './components/Footer'
import FooterParser from './components/FooterParser'
import Headerr from './components/Header'

import GAvalue from './components/GAvalue.txt'
import socketDataEN from './components/socketDataEN.json'

const { Header } = Layout

ReactGA.initialize('UA-155180737-1')
ReactGA.pageview(window.location.pathname + window.location.search)

const App = () => {
  const [language, setLanguage] = useState('en')
  const [menu_language, setLanguageMenu] = useState(socketDataEN.menu)
  const [option, setOption] = useState('socket')
  useEffect(() => console.log(language))
  useEffect(() => console.log(option))
  switch (option) {
    case 'socket':
      return (
        <Layout className="layout">
          <Headerr
            language={language}
            menu_language={menu_language}
            setLanguage={setLanguage}
            setLanguageMenu={setLanguageMenu}
            option={option}
            setOption={setOption}
          />
          <FadeIn>
            <MainContent
              language={language}
              menu_language={menu_language}
              className="main-content"
            />
            <Footer menu_language={menu_language} />
          </FadeIn>
        </Layout>
      )
    case 'library':
      return (
        <Layout className="layout">
          <FadeIn>
            <Headerr
              language={language}
              setLanguage={setLanguage}
              option={option}
              setOption={setOption}
              menu_language={menu_language}
              setLanguageMenu={setLanguageMenu}
            />
            <MainContentMeter language={language} setLanguage={setLanguage} />
            <FooterParser />
          </FadeIn>
        </Layout>
      )
  }
}

export default App
