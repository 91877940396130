import React from 'react'
import { Layout, Divider } from 'antd'

import discord from '../assets/images/discord.png'
import logo from '../assets/images/logo-method-main.webp'

const FooterParser = () => {
  return (
    <Layout.Footer className="footer">
      <Divider />
      <strong>For questions or suggestions:</strong>
      <br />
      <img class="discord-icon" src={discord} /> jpark#0450 (Zephyrs (Nox))
      <br />
      <br />
      <strong>Alpha release 0.0.2</strong>
      <br />
      tested on Google chrome Version 80.0.3987.116
      <br />
      Only work with English & French game-log version only
      <br />
      Indirect damages (glyph, poison, etc) may mess with the results of Damages
      done, and Healing done
      <br />
      (number of spells casted, crit rates, heal received, damage received are
      100% accurate)
      <br />
      <br />
      <strong>How to use:</strong>
      <br />
      1) Start Wakfu and before enter a fight type in start keywords in chat
      (eg. "Stasis21 Badger Boss")
      <br />
      2) Finish the fight
      <br />
      3) Type in end keywords (eg. "fight end.")
      <br />
      4) Navigate to Ankama launcher -> wakfu -> Option -> installation
      Directory -> "See the logs" -> "log" folder
      <br />
      5) get the path of the folder (eg. C:\Users\*username*\AppData
      \Roaming\zaap\wakfu\logs)
      <br />
      6) CLICK the box above and paste in the path, and select "wakfu_chat"
      <br />
      (Drag and drop file into the box will give error!)
      <br />
      7) type in start/end keywords, then submit
      <br />
      <br />
      <strong>Special thanks to ifal for the idea !</strong>
      <br />
      <img class="footer-logo" src={logo} />
      <br />
      WAKFU MMORPG : © 2020 Ankama Studio. All rights reserved.
      <br />
      "MethodWakfu" and its derived services are unofficial sites with no link
      to Ankama.
      <br />
      <br />
    </Layout.Footer>
  )
}

export default FooterParser
