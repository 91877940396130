import React from 'react'
import { Table } from 'antd'
import FadeIn from 'react-fade-in'

import redSocket from '../assets/images/RedSocket.png'
import greenSocket from '../assets/images/GreenSocket.png'
import blueSocket from '../assets/images/BlueSocket.png'

import socketDataEN from './socketDataEN.json'
import socketDataES from './socketDataES.json'
import socketDataIT from './socketDataIT.json'
import socketDataFR from './socketDataFR.json'
import socketDataPT from './socketDataPT.json'

const OutputTable = props => {
  const columns = [
    {
      title: props.menu_language.name,
      dataIndex: 'name',
      key: 'name',
      width: 140,
      align: 'center',
      render: tuple => {
        return (
          <FadeIn>
            <a href={tuple.link} target="blank">
              {tuple.name}
            </a>
          </FadeIn>
        )
      },
    },
    {
      title: props.menu_language.gem_slot,
      dataIndex: 'gemSlots',
      key: 'gemSlots',
      width: 160,
      align: 'center',
      sorter: a => a.gemSlots.matched,
      defaultSortOrder: 'descend',
      render: ({ sublimationsSlot, matched }) => {
        let permutations = []
        for (let i = 0; i < sublimationsSlot.length; ++i) {
          switch (sublimationsSlot.charAt(i)) {
            case 'R':
              permutations.push(
                <img key={i * 3} alt="R" className="gemSize" src={redSocket} />
              )
              break
            case 'G':
              permutations.push(
                <img
                  key={i * 3 + 1}
                  alt="G"
                  className="gemSize"
                  src={greenSocket}
                />
              )
              break
            case 'B':
              permutations.push(
                <img
                  key={i * 3 + 2}
                  alt="B"
                  className="gemSize"
                  src={blueSocket}
                />
              )
              break
            default:
              break
          }
        }
        if (matched) {
          return (
            <FadeIn>
              <div className="gemSlotsMatched">{permutations}</div>
            </FadeIn>
          )
        } else {
          return (
            <FadeIn>
              <div className="gemSlots">{permutations}</div>
            </FadeIn>
          )
        }
      },
    },
    {
      title: props.menu_language.description,
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      render: ({ description, maxStack }) => {
        return (
          <FadeIn>
            <div>
              {description}
              <br />
              {' (Max : ' + maxStack + ')'}
            </div>
          </FadeIn>
        )
      },
    },
    {
      title: props.menu_language.source,
      dataIndex: 'obtain',
      key: 'obtain',
      align: 'center',
      render: ({ obtain }) => {
        return (
          <FadeIn>
            <div>{obtain}</div>
          </FadeIn>
        )
      },
    },
  ]

  var data = []
  var index = 0

  props.permutations.map(function(v) {
    var sublimations
    switch (props.language) {
      case 'en':
        sublimations = socketDataEN.socketType[v]
        break
      case 'es':
        sublimations = socketDataES.socketType[v]
        break
      case 'it':
        sublimations = socketDataIT.socketType[v]
        break
      case 'fr':
        sublimations = socketDataFR.socketType[v]
        break
      case 'pt':
        sublimations = socketDataPT.socketType[v]
        break
    }

    sublimations.map(function(s) {
      data.push({
        key: index,
        name: { name: s.name, link: s.link },
        description: {
          description: s.description,
          maxStack: s.maxStack,
        },
        obtain: { obtain: s.obtain },
        maxStack: s.maxStack,
        gemSlots: { sublimationsSlot: v, matched: props.matched.includes(v) },
      })
      index++
    })
  })

  return <Table columns={columns} dataSource={data} />
}

export default OutputTable
