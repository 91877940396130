import React from 'react'
import { Layout, Divider } from 'antd'

import TxtInput from './TxtInput'
import OutputTableParser from './OutputTableParser'
import FadeIn from 'react-fade-in'
import languageMeter from './languageMeter.json'

const { Content } = Layout

class MainContentMeter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      start: 'this is start',
      end: 'this is end',
      parsingContent: 'empty',
      stateUserBase: [],
      tableUserBase: [],
      userBaseIndex: 0,
    }
  }

  updateStart = p => {
    this.setState({ start: p }, function() {
      console.log(this.state.start)
    })
  }

  updateEnd = p => {
    this.setState({ end: p }, function() {
      console.log(this.state.end)
    })
  }

  updateUserBase = p => {
    this.setState({ stateUserBase: p }, function() {
      console.log('%c =============State UserBase=============', 'color: red')
      console.log(this.state.stateUserBase)
    })
  }

  iterateArray = () => {
    const output = []
    for (var n = 0; n < this.state.stateUserBase.length; n++) {
      output.push(
        <OutputTableParser
          stateUserBase={this.state.stateUserBase}
          userBaseIndex={n}
          updateTable={this.updateTable}
        />
      )
    }
    return output
  }

  render() {
    return (
      <div>
        <Content className="content">
          <Divider />
          <TxtInput
            start={this.state.start}
            end={this.state.end}
            parsingContent={this.state.parsingContent}
            language={this.props.language}
            setLanguage={this.props.setLanguage}
            updateStart={this.updateStart}
            updateEnd={this.updateEnd}
            updateUserBase={this.updateUserBase}
            stateUserBase={this.state.stateUserBase}
          />

          {this.iterateArray()}
          {this.output}
        </Content>
      </div>
    )
  }
}

export default MainContentMeter
