// Citation: https://stackoverflow.com/questions/37579994/generate-permutations-of-javascript-array

const permutations = xs => {
  if (xs.includes('GOLD')) {
    var index = xs.indexOf('GOLD')
    var r = [...xs]
    r[index] = 'R'

    var g = [...xs]
    g[index] = 'G'

    var b = [...xs]
    b[index] = 'B'
    var out = permutations(r)
      .concat(permutations(g))
      .concat(permutations(b))

    // Filter out the repeats
    var tmp = []
    out = out.filter(function(v) {
      if (tmp.indexOf(v.toString()) < 0) {
        tmp.push(v.toString())
        return v
      }
    })

    return out
  }

  var arr = allPermuations(xs).filter(
    a => a[0] !== undefined && a[1] !== undefined && a[2] !== undefined
  )

  arr.map(function(v) {
    v.splice(-1)
  })

  // Filter out the repeats
  var tmp2 = []
  arr = arr.filter(function(v) {
    if (tmp2.indexOf(v.toString()) < 0) {
      tmp2.push(v.toString())
      return v
    }
  })

  var concatArr = []
  arr.map(function(v) {
    concatArr.push(v.join(''))
  })

  return concatArr
}

const allPermuations = xs => {
  let ret = []

  for (let i = 0; i < xs.length; i = i + 1) {
    let rest = allPermuations(xs.slice(0, i).concat(xs.slice(i + 1)))

    if (!rest.length) {
      ret.push([xs[i]])
    } else {
      for (let j = 0; j < rest.length; j = j + 1) {
        ret.push([xs[i]].concat(rest[j]))
      }
    }
  }
  return ret
}

export default permutations
