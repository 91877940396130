import React, { useState } from 'react'
import { Upload, Icon, Input, Button, message } from 'antd'
import languageMeter from './languageMeter.json'

const { Dragger } = Upload

// TO CHANGE BACK to blank
var start = 'blank'
// TO CHANGE BACK to blank
const regex = /(\d)\s+(?=\d)/g
const subst = `$1`

var end = 'blank'
var unfilteredContent = 'empty'
var parsingContent = 'empty'
var parsingArray = []
let dataBase = []
let userBase = []
var currentSpell = 0
var currentUser = 0

let i = 0
let n = 0
let m = 0

//class
class Person {
  constructor(name) {
    this.name = name
    this.spellDeck = []
    this.dmgReceived = []
    this.sumDmgReceived = 0
    this.healRecieved = []
    this.sumHealReceived = 0
    this.totalDmgDone = 0
    this.totalHealDone = 0
  }
}

class Spell {
  constructor(spellName) {
    this.spellName = spellName
    this.count = 1
    this.critCount = 0
    this.critPercent = 0
    this.totalDmg = 0
    this.totalHeal = 0
    this.dmgLog = []
    this.healLog = []
  }
  countIncrease() {
    return this.count++
  }
  critCountIncrease() {
    return this.critCount++
  }
  critPercentCal() {
    this.critPercent = ((this.critCount / this.count) * 100).toFixed(2)
  }
}
// inputting text file
const handleSubmit = props => () => {
  props.updateStart(start)
  props.updateEnd(end)

  if (!unfilteredContent.includes(start) || !unfilteredContent.includes(end)) {
    message.error('invalid keywords (not detected)')
    return
  }

  parsingContent = unfilteredContent.substring(
    unfilteredContent.lastIndexOf(start),
    unfilteredContent.lastIndexOf(end)
  )
  console.log('%c =========unfilteredContent========', 'color: silver')
  console.log(unfilteredContent)
  console.log('%c =========start========', 'color: red')
  console.log(start)
  console.log('%c =========parsingContent========', 'color: blue')
  console.log(parsingContent)

  parsingArray = parsingContent.split('\n')

  console.log('%c =========parsingArray========', 'color: red')
  console.log(parsingArray[0])
  console.log(parsingArray)

  // IF french detected
  if (
    parsingContent.includes('[Information (jeu)]') &&
    parsingContent.includes('lance le sort')
  ) {
    console.log('%c =========testthread========', 'color: blue')

    for (var f = 0; f < parsingArray.length; f++) {
      if (
        parsingArray[f].includes('lance le sort') &&
        !parsingArray[f].includes('(Critiques)')
      ) {
        var tempParse = parsingArray[f].concat('.')
        parsingArray[f] = tempParse
      } else if (
        parsingArray[f].includes('lance le sort') &&
        parsingArray[f].includes('(Critiques)')
      ) {
        var tempParse = parsingArray[f].replace(' (Critiques)', '. (Critiques)')
        parsingArray[f] = tempParse
      }
    }
  }

  console.log(props.language)
  console.log(parsingArray)

  //EASTER EGG------------------------------------------------
  if (parsingContent.includes('Duren casts')) {
    message.warning('ohi duden.')
  }
  if (parsingContent.includes('Ifal casts')) {
    message.warning("Uni's shadow has entered the chat.")
  }
  if (parsingContent.includes('Seven Two Six casts')) {
    message.warning('Admin has entered the chat.')
  }
  if (parsingContent.includes('Ten-Punch Sip casts')) {
    message.warning('#1 Iop has entered the chat.')
  }
  if (parsingContent.includes('Hoopla casts')) {
    message.warning('Eca God has entered the chat.')
  }
  if (parsingContent.includes('Bankotsu casts')) {
    message.warning('Banko op')
  }
  if (parsingContent.includes('Barinade casts')) {
    message.warning('Bari? BARI? BBAAARI?')
  }
  if (parsingContent.includes('Nutella Covered Balls casts')) {
    message.warning('#1 Feca has entered the chat.')
  }
  //END EASTER EGG------------------------------------------------

  // Add User
  for (i = 0; i < parsingArray.length; i++) {
    var temp
    var temp2
    var temp3
    var temp4

    var crit = false
    var newUser = true
    var newSpell = true

    // user name
    if (
      parsingArray[i].includes(languageMeter[props.language].hp) &&
      (parsingArray[i].includes(': -') || parsingArray[i].includes(': +'))
    ) {
      if (parsingArray[i].includes(': -')) {
        var temp5 = parsingArray[i].substring(
          parsingArray[i].lastIndexOf(': -'),
          parsingArray[i].lastIndexOf(languageMeter[props.language].GameLog) +
            languageMeter[props.language].IndexGameLog
        )
      }
      if (parsingArray[i].includes(': +')) {
        var temp5 = parsingArray[i].substring(
          parsingArray[i].lastIndexOf(': +'),
          parsingArray[i].lastIndexOf(languageMeter[props.language].GameLog) +
            languageMeter[props.language].IndexGameLog
        )
      }
      for (var no = 0; no < userBase.length; no++) {
        if (userBase[no].name.includes(temp5)) {
          newUser = false
          break
        } else if (temp5 !== '' && !userBase[no].name.includes(temp5)) {
          newUser = true
        }
      }
      if (newUser) {
        temp2 = new Person(temp5)
        userBase.push(temp2)
      }
    }

    if (parsingArray[i].includes(languageMeter[props.language].casts)) {
      // user name
      temp = parsingArray[i].substring(
        parsingArray[i].lastIndexOf(languageMeter[props.language].GameLog) +
          languageMeter[props.language].IndexGameLog,
        parsingArray[i].lastIndexOf(languageMeter[props.language].casts) - 1
      )

      // spell name
      temp3 = parsingArray[i].substring(
        parsingArray[i].lastIndexOf(languageMeter[props.language].casts) +
          languageMeter[props.language].IndexSpellName,
        parsingArray[i].lastIndexOf('.')
      )

      if (parsingArray[i].includes(languageMeter[props.language].Critical)) {
        crit = true
      }
      // Add user & spell
      for (n = 0; n < userBase.length; n++) {
        for (m = 0; m < userBase[n].spellDeck.length; m++) {
          if (userBase[n].spellDeck[m].spellName === temp3) {
            currentSpell = m
            newSpell = false
            userBase[n].spellDeck[m].countIncrease()
            if (crit) {
              userBase[n].spellDeck[m].critCountIncrease()
            }
          }
        }
        if (userBase[n].name.includes(temp)) {
          currentUser = n
          newUser = false
          break
        }
      }

      if (newUser) {
        currentUser = n
        currentSpell = 0
        m = 0
        temp2 = new Person(temp)
        userBase.push(temp2)
        // for (var temp7 = 0; temp7 < userBase.length; temp7++) {
        //   if (temp5 !== '' && !userBase[temp7].name.includes(temp5)) {
        //     temp2 = new Person(temp5)
        //     userBase.push(temp2)
        //   }
        // }
      }
      if (newSpell) {
        currentSpell = m
        temp4 = new Spell(temp3)
        userBase[n].spellDeck.push(temp4)
        if (crit && typeof userBase[n].spellDeck[m] !== 'undefined') {
          userBase[n].spellDeck[m].critCountIncrease()
        }
      }
    }
    if (
      parsingArray[i].includes(': -') &&
      parsingArray[i].includes(languageMeter[props.language].hp)
    ) {
      // get dmg receiver name to add to dmgReceived array
      // console.log('%c find receiver ', 'color: green')
      // console.log(temp5)
      userBase[currentUser].spellDeck[currentSpell].dmgLog.push(parsingArray[i])

      var x = parsingArray[i].replace(/,/g, '').toString()
      var x = x.replace(regex, subst)

      var y = parseInt(
        x.substring(
          x.lastIndexOf(': -') + 3,
          x.lastIndexOf(' ' + languageMeter[props.language].hp)
        )
      )
      userBase[currentUser].spellDeck[currentSpell].totalDmg =
        userBase[currentUser].spellDeck[currentSpell].totalDmg + y

      for (var v = 0; v < userBase.length; v++) {
        if (userBase[v].name.includes(temp5)) {
          userBase[v].dmgReceived.push(parsingArray[i])
          // sum damage received
          var x = parsingArray[i].replace(/,/g, '').toString()
          var x = x.replace(regex, subst)
          // console.log('%c FIND X ', 'color: green')
          // console.log(x)
          var y = parseInt(
            x.substring(
              x.lastIndexOf(': -') + 3,
              x.lastIndexOf(' ' + languageMeter[props.language].hp)
            )
          )
          // console.log('%c FIND Y ', 'color: green')
          // console.log(y)
          userBase[v].sumDmgReceived = userBase[v].sumDmgReceived + y
        }
      }
    }
    if (
      parsingArray[i].includes(': +') &&
      parsingArray[i].includes(languageMeter[props.language].hp)
    ) {
      // console.log('%c heal ', 'color: orange')
      // console.log(parsingArray[i])
      // console.log(userBase[n])
      // console.log(userBase[currentUser].spellDeck[currentSpell])
      // console.log(
      //   'currentUser is : ' +
      //     currentUser +
      //     ', currentSpell is : ' +
      //     currentSpell
      // )
      // console.log('n is : ' + n + ', m is : ' + m)
      userBase[currentUser].spellDeck[currentSpell].healLog.push(
        parsingArray[i]
      )
      var x = parsingArray[i].replace(/,/g, '').toString()
      var x = x.replace(regex, subst)

      var y = parseInt(
        x.substring(
          x.lastIndexOf(': +') + 3,
          x.lastIndexOf(' ' + languageMeter[props.language].hp)
        )
      )
      userBase[currentUser].spellDeck[currentSpell].totalHeal =
        userBase[currentUser].spellDeck[currentSpell].totalHeal + y

      for (var v = 0; v < userBase.length; v++) {
        if (userBase[v].name.includes(temp5)) {
          userBase[v].healRecieved.push(parsingArray[i])
          // sum Heal received
          var x = parsingArray[i].replace(/,/g, '').toString()
          var x = x.replace(regex, subst)

          var y = parseInt(
            x.substring(
              x.lastIndexOf(': +') + 3,
              x.lastIndexOf(' ' + languageMeter[props.language].hp)
            )
          )

          userBase[v].sumHealReceived = userBase[v].sumHealReceived + y
        }
      }
    }
  }

  // calculate critical %
  for (n = 0; n < userBase.length; n++) {
    for (m = 0; m < userBase[n].spellDeck.length; m++) {
      userBase[n].spellDeck[m].critPercentCal()
    }
  }

  // calculate total dmg from all spell
  for (n = 0; n < userBase.length; n++) {
    for (m = 0; m < userBase[n].spellDeck.length; m++) {
      userBase[n].totalDmgDone =
        userBase[n].totalDmgDone + userBase[n].spellDeck[m].totalDmg
      userBase[n].totalHealDone =
        userBase[n].totalHealDone + userBase[n].spellDeck[m].totalHeal
    } // add comma between numbers
  }

  // console.log(userBase[0].name)
  // console.log(userBase[1].name)
  // console.log(userBase[2].name)
  // console.log(userBase)
  // console.log('=======================================')
  // console.log(userBase[0].spellDeck[0].spellName)
  // console.log('=======================================')
  console.log('%c =======================================', 'color: orange')
  // console.table(userBase)
  // console.table(userBase[0].spellDeck)
  // console.table(userBase[0].spellDeck[0])

  props.updateUserBase(userBase)

  // only print out after 2 click
  // console.log('%c =====print stateUserBase from txtinput======', 'color: orange')
  // console.log(props.stateUserBase)
}

const handleStartSubmit = value => {
  console.log('called handleStart')
  start = value
}
const handleEndSubmit = value => {
  console.log('called handleEnd')
  end = value
}

const handleReset = () => {
  window.location.reload(false)
}

// inputting text file
const TxtInput = props => {
  let fileReader

  const txt = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  const handleFileRead = e => {
    const content = fileReader.result
    unfilteredContent = content

    if (content.includes('[Information (jeu)]')) {
      props.setLanguage('fr')
    }
  }

  const handleFileChosen = file => {
    fileReader = new FileReader()
    fileReader.onloadend = handleFileRead
    fileReader.readAsText(file)
  }

  return (
    <div className="box">
      <div onChange={e => handleFileChosen(e.target.files[0])}>
        <div className="fixedheight">
          <div className="inputbox">
            <Dragger
              {...txt}
              type="file"
              id="file"
              className="input-file"
              accept=".log"
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">CLICK to upload only</p>
              <p className="ant-upload-hint">
                Full instruction below
                <br />
                <br />
              </p>
            </Dragger>
          </div>
        </div>
      </div>
      <div className="inputbox2">
        <Input
          className="inputtxt"
          placeholder="Start Keyword"
          // onChange={e => handleStartSubmit('test 1')}
          onChange={e => handleStartSubmit(e.target.value)}
          // TO CHANGE BACK
        />
        <Input
          className="inputtxt"
          placeholder="End Keyword"
          // onChange={e => handleEndSubmit('test1')}
          onChange={e => handleEndSubmit(e.target.value)}
          // TO CHANGE BACK
        />

        <div className="Button txtinputbutton">
          <Button
            className="ant-btn"
            type="primary"
            onClick={handleSubmit(props)}
          >
            Submit
          </Button>
          <Button className="ant-btn" type="danger" onClick={handleReset}>
            Reset
          </Button>
        </div>
      </div>
    </div>
  )
}
export default TxtInput
