import React from 'react'
import { Drawer, List, Avatar, Divider, Col, Row } from 'antd'
import FadeIn from 'react-fade-in'

//---------------------------------------------------------------
const pStyle = {
  fontSize: 28,
  color: 'rgba(0,0,0,0.85)',
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
}
//---------------------------------------------------------------
//---------------------------------------------------------------
const OutputTable = ({ title, content }) => (
  <div
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
      color: 'rgba(0,0,0,0.65)',
    }}
  >
    <p
      style={{
        marginRight: 8,
        display: 'inline-block',
        color: 'rgba(0,0,0,0.85)',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
)
//---------------------------------------------------------------
class OutputTableParser extends React.Component {
  constructor(props) {
    super(props)
  }

  state = { visible: false }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  iterateArray = () => {
    const output = []
    for (
      var n = 0;
      n < this.props.stateUserBase[this.props.userBaseIndex].spellDeck.length;
      n++
    ) {
      output.push(
        <Row>
          <Col span={24}>
            <OutputTable
              title={
                this.props.stateUserBase[this.props.userBaseIndex].spellDeck[n]
                  .spellName
              }
              content={
                'Casted : ' +
                this.props.stateUserBase[this.props.userBaseIndex].spellDeck[n]
                  .count +
                ', Critical : ' +
                this.props.stateUserBase[this.props.userBaseIndex].spellDeck[n]
                  .critCount +
                ' (' +
                this.props.stateUserBase[this.props.userBaseIndex].spellDeck[n]
                  .critPercent +
                '%)' +
                ' Total Damage: ' +
                this.props.stateUserBase[this.props.userBaseIndex].spellDeck[n]
                  .totalDmg +
                ' Total Heal: ' +
                this.props.stateUserBase[this.props.userBaseIndex].spellDeck[n]
                  .totalHeal
              }
            />
          </Col>
        </Row>
      )
    }
    return output
  }

  createTable = props => {
    let table = []
    table.push(
      <div>
        <List
          dataSource={[
            {
              name: this.props.stateUserBase[this.props.userBaseIndex].name,
            },
          ]}
          bordered
          renderItem={item => (
            <List.Item
              key={item.id}
              actions={[
                <a onClick={this.showDrawer} key={`a-${item.id}`}>
                  View Profile
                </a>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar src="https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png" />
                }
                title={<a href="https://ant.design/index-cn">{item.name}</a>}
                description={[
                  'Damage done: ' +
                    this.props.stateUserBase[this.props.userBaseIndex]
                      .totalDmgDone +
                    ' Healing done: ' +
                    this.props.stateUserBase[this.props.userBaseIndex]
                      .totalHealDone,
                ]}
              />
            </List.Item>
          )}
        />
      </div>
    )
    return table
  }

  createTable2 = props => {
    let table2 = []
    table2.push(
      <div>
        <Drawer
          width={780}
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <p style={{ ...pStyle, marginBottom: 24 }}>
            <b>{this.props.stateUserBase[this.props.userBaseIndex].name}</b>
          </p>
          <p style={pStyle}>####</p>
          <Row>
            <Col span={12}>
              <OutputTable
                title="Damage Done"
                content={
                  this.props.stateUserBase[this.props.userBaseIndex]
                    .totalDmgDone
                }
              />
            </Col>
            <Col span={12}>
              <OutputTable
                title="Healing Done"
                content={
                  this.props.stateUserBase[this.props.userBaseIndex]
                    .totalHealDone
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <OutputTable
                title="Damage Received "
                content={
                  this.props.stateUserBase[this.props.userBaseIndex]
                    .sumDmgReceived
                }
              />
            </Col>
            <Col span={12}>
              <OutputTable
                title="Heal Received "
                content={
                  this.props.stateUserBase[this.props.userBaseIndex]
                    .sumHealReceived
                }
              />
            </Col>
          </Row>

          {this.iterateArray()}
          <Row>
            <Col span={12}>
              <OutputTable title="" content="" />
            </Col>
          </Row>
        </Drawer>
      </div>
    )

    return table2
  }

  render() {
    if (this.props.stateUserBase.length === 0) {
      return null
    } else {
      return (
        <FadeIn>
          <table class="autoMargin">
            {this.createTable()}
            {this.createTable2()}
          </table>
        </FadeIn>
      )
    }
  }
}
//---------------------------------------------------------------
export default OutputTableParser
